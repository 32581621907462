Ember.TEMPLATES["javascripts/composer/perspective-message"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],\"closeMessage\"]],[8],[1,[28,\"d-icon\",[\"close\"],null],false],[9],[0,\"\\n\\n\"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"perspective.perspective_messages\"],null],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"messages\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"perspective.perspective_warning\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/composer/perspective-message"}});
define("discourse/plugins/discourse-perspective-api/initializers/discourse-perspective", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "I18n", "bootbox"], function (_exports, _pluginApi, _ajax, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _initialize(api) {
    var siteSettings = api.container.lookup("site-settings:main");
    api.modifyClass("controller:composer", {
      _perspective_checked: null,
      perspectiveSave: function perspectiveSave(force) {
        var _this = this;

        this.set("_perspective_checked", true);
        this.save(force).finally(function () {
          _this.set("_perspective_checked", false);
        });
      },
      save: function save(force) {
        var _this2 = this;

        // same validation code from controller
        if (this.disableSubmit && !this._perspective_checked) {
          return;
        }

        if (!this.showWarning) {
          this.set("model.isWarning", false);
        }

        var composer = this.model;

        if (composer.cantSubmitPost) {
          this.set("lastValidatedAt", Date.now());
          return;
        } else {
          // disable composer submit during perspective validation
          this.set("disableSubmit", true);
        }

        var bypassPM = !siteSettings.perspective_check_private_message && this.get("topic.isPrivateMessage");
        var bypassSecuredCategories = !siteSettings.perspective_check_secured_categories && this.get("model.category.read_restricted");
        var bypassCheck = bypassPM || bypassSecuredCategories;

        if (!bypassCheck && !this._perspective_checked) {
          var concat = "";
          ["title", "raw", "reply"].forEach(function (item) {
            var content = composer.get(item);

            if (content) {
              concat += "".concat(content, " ");
            }
          });
          concat.trim();
          (0, _ajax.ajax)("/perspective/post_toxicity", {
            type: "POST",
            data: {
              concat: concat
            }
          }).then(function (response) {
            if (response && response["score"] !== undefined) {
              var message = _I18n.default.t("perspective.perspective_message");

              var buttons = [{
                label: _I18n.default.t("perspective.composer_continue"),
                class: "btn",
                callback: function callback() {
                  return _this2.perspectiveSave(force);
                }
              }, {
                label: _I18n.default.t("perspective.composer_edit"),
                class: "btn-primary",
                callback: function callback() {
                  _this2.set("disableSubmit", false);
                }
              }];

              _bootbox.default.dialog(message, buttons);

              return;
            } else {
              _this2.perspectiveSave(force);
            }
          }).catch(function () {
            // fail silently
            _this2.perspectiveSave(force);
          });
        } else {
          this.set("disableSubmit", false);
          return this._super(force);
        }
      }
    });
  }

  var _default = {
    name: "discourse-perspective-api",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.perspective_enabled && siteSettings.perspective_notify_posting_min_toxicity_enable) {
        (0, _pluginApi.withPluginApi)("0.8.17", _initialize);
      }
    }
  };
  _exports.default = _default;
});

